import Vuex from 'vuex';
import { request, sessionSaveItem } from '@/utils/utils';
import {
  handleRolls,
  bestRolls,
  getBestPackage,
  GetFastestDomain,
} from './utils';

export const createState = () => new Vuex.Store({
  state: {
    content: {},
    isMd: false,
    langFlag: 0,
    userId: '',
    originalPackages: [],
    originalRolls: [],
    activePackage: 5,
    enabled: {},
    currency: {},
    cdnPay: '',
  },
  mutations: {
    content(state, content) {
      state.content = content;
    },
    isMd(state, isMd) {
      state.isMd = isMd;
    },
    langFlag(state, langFlag) {
      state.langFlag = langFlag;
    },
    userId(state, userId) {
      state.userId = userId;
    },
    originalPackages(state, originalPackages) {
      state.originalPackages = originalPackages;
    },
    originalRolls(state, originalRolls) {
      state.originalRolls = originalRolls;
    },
    activePackage(state, activePackage) {
      state.activePackage = activePackage;
    },
    enabled(state, enabled) {
      state.enabled = enabled;
    },
    currency(state, currency) {
      state.currency = currency;
    },
    cdnPay(state, cdnPay) {
      state.cdnPay = cdnPay;
    },
  },
  actions: {
    async request(ctx, { uid }) {
      const [packages, rolls] = await Promise.all([
        request({
          url: '/plans/packages',
          query: { uid },
          noShowLoading: true,
        }),
        request({
          url: '/plans/rolls',
          query: { uid },
          noShowLoading: true,
        }),
      ]);
      try {
        // 年,季,月,半年 -> 屏蔽半年套餐
        // 已登录并且是SVIP套餐可见用户才显示SVIP套餐 -> 屏蔽SVIP套餐
        const originalPackages = packages[0] ? packages[1].data.data.packages : [];
        const originalRolls = rolls[0] ? rolls[1].data.data.rolls : [];
        const enabled = packages[0] ? packages[1].data.data.enabled : {};
        const currency = rolls[0] ? rolls[1].data.data.currency : {};
        const defaultPay = packages[0] ? packages[1].data.data.cdn_pay : "";
        const cdnPays = packages[0] ? packages[1].data.data.cdn_pays : [];
        const cdnPaysBak = packages[0] ? packages[1].data.data.cdn_pays_bak : [];
        let cdnPay = await GetFastestDomain(cdnPays, cdnPaysBak, defaultPay)
        ctx.commit('userId', uid);
        ctx.commit('originalPackages', originalPackages);
        ctx.commit('originalRolls', originalRolls);
        ctx.commit('enabled', enabled);
        ctx.commit('currency', currency);
        ctx.commit('cdnPay', cdnPay);
        sessionSaveItem("dx-captcha", enabled.dx ? 1 : 0);
      } catch (error) {
        console.log(error.stack)
      }
    },
  },
  getters: {
    packages(state) {
      const r = handleRolls(state.originalPackages, state.originalRolls);
      const packages = state.originalPackages
        // 处理价格
        .map((item, index) => {
          // 写死单月价格。按3设备月套餐计算。3设备月套餐15美金，每个设备5美金
          var monthBasePrice = (15).toFixed(2);
          const { time, price } = item;
          let rolls = [];
          let betterPrice = price;
          if (r[index]?.length > 0) {
            rolls = bestRolls(item, r[index]);
            betterPrice = rolls[0].betterPrice;
          }
          return {
            ...item,
            rolls,
            betterPrice: String(betterPrice).replace('.00', ''),
            monthBestPrice: (betterPrice / time).toFixed(2),
            monthBasePrice,
          };
        })
        // 过滤价格小于等于0的套餐
        .filter(item => item.monthBestPrice > 0)
        // 计算最优惠价格的优惠力度(按折扣方式计算)
        .map(item => {
          const { price, betterPrice } = item;
          const v = betterPrice / price;
          const off = state.langFlag === 1
            ? Math.round((1 - v) * 100)
            : (v * 10).toFixed(1).replace('.0', '');
          return { ...item, off: off + state.content.off };
        })
        // 处理单位
        .map(item => ({
          ...item,
          whichMoney: item.currency === 'HKD'
            ? 'HK$'
            : item.currency === 'CNY' ? '￥' : '$',
          whichMoney1: item.currency === 'HKD'
            ? state.content.hk$
            : item.currency === 'CNY' ? '￥' : state.content.us$,
        }))
        // 处理名字
        .map(item => ({
          ...item,
          name: state.content.names[item.package],
        }))
        // 处理标识图像
        .map(item => ({
          ...item,
          src: { 3: 'package3.png', 5: 'package12.png', 2: 'package1.png' }[item.package],
        }))
        // 处理选中状态
        .map(item => ({
          ...item,
          isActive: item.package === state.activePackage,
        }));
      // 处理套餐排序 pc 3 5 2 m 5 3 2 多余则往后排 少于则只排仅有的
      const sort = state.isMd ? [2, 5, 3] : [2, 3, 5];
      sort.forEach(p => {
        const index = packages.findIndex(item => item.package === p);
        if (index > 0) packages.unshift(...packages.splice(index, 1));
      });
      // 处理没有选中套餐的情况 选中第一个/最中间的套餐
      if (packages.length > 0 && packages.every(item => !item.isActive)) {
        state.isMd
          ? (packages[Math.floor(packages.length / 2)].isActive = true)
          : packages[0].isActive = true;
      }
      return packages;
    },
    desc(state, getters) {
      return getters.packages.find(item => item.package === state.activePackage)?.isSvip
        ? state.content.descSvip
        : state.content.desc;
    },
    bestPackage(state, getters) {
      return getBestPackage(getters.packages);
    },
    packageInfo(state, getters) {
      return getters.packages.find(item => item.package === state.activePackage);
    },
  },
});
