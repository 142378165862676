import Vuex from 'vuex';
import { request } from '@/utils/utils';
import {
  handleRolls,
  bestRolls,
  GetFastestDomain,
} from './utils';

export const createState = () => new Vuex.Store({
  state: {
    content: {},
    langFlag: 0,
    userId: '',
    activeDeviceNumber: 0,
    activePackage: null,
    originalPackages: [],
    originalRolls: [],
    enabled: {},
    currency: {},
    cdnPay: '',
  },
  mutations: {
    content(state, content) {
      state.content = content;
    },
    langFlag(state, langFlag) {
      state.langFlag = langFlag;
    },
    userId(state, userId) {
      state.userId = userId;
    },
    activeDeviceNumber(state, activeDeviceNumber) {
      state.activeDeviceNumber = activeDeviceNumber;
    },
    activePackage(state, activePackage) {
      state.activePackage = activePackage;
    },
    originalPackages(state, originalPackages) {
      state.originalPackages = originalPackages;
    },
    originalRolls(state, originalRolls) {
      state.originalRolls = originalRolls;
    },
    enabled(state, enabled) {
      state.enabled = enabled;
    },
    currency(state, currency) {
      state.currency = currency;
    },
    cdnPay(state, cdnPay) {
      state.cdnPay = cdnPay;
    },
  },
  actions: {
    async request(ctx, uid) {
      const [packages, rolls] = await Promise.all([
        request({
          url: '/plans/packages_devices',
          query: {
            uid,
          },
          noShowLoading: true,
        }),
        request({
          url: '/plans/rolls',
          query: {
            uid,
          },
          noShowLoading: true,
        }),
      ]);
      const defaultPay = packages[0] ? packages[1].data.data.cdn_pay : "";
      const cdnPays = packages[0] ? packages[1].data.data.cdn_pays : [];
      const cdnPaysBak = packages[0] ? packages[1].data.data.cdn_pays_bak : [];
      let cdnPay = await GetFastestDomain(cdnPays, cdnPaysBak, defaultPay)
      ctx.commit('userId', uid);
      ctx.commit('originalPackages', packages[0] ? packages[1].data.data.packages : []);
      ctx.commit('originalRolls', rolls[0] ? rolls[1].data.data.rolls : []);
      ctx.commit('enabled', packages[0] ? packages[1].data.data.enabled : {});
      ctx.commit('currency', rolls[0] ? rolls[1].data.data.currency : {});
      ctx.commit('cdnPay', cdnPay);
    },
  },
  getters: {
    allPackages(state) {
      const {
        originalPackages,
        originalRolls,
        langFlag,
        content,
      } = state;
      const rolls = handleRolls(originalPackages, originalRolls);

      return originalPackages
        // 获取套餐最优惠价格
        .map((item, index) => {
          // 多设备强行加入单月价格。按3设备月套餐计算。3设备月套餐15美金，每个设备5美金
          var monthBasePrice = (5 * item.device_count).toFixed(2); 
          // 当前套餐没有优惠券则显示原价
          if (!rolls[index] || rolls[index].length <= 0) {
            return {
              ...item,
              bestPrice: item.price,
              monthBasePrice,
              monthBestPrice: (item.price / item.time).toFixed(2),
              rolls: [],
            };
          }

          const currentRolls = bestRolls(item, rolls[index]);
          let bestPrice = currentRolls[0].betterPrice;
          let betterPrice = bestPrice;
          bestPrice >= 1 && (bestPrice = Math.floor(bestPrice));

          return {
            ...item,
            bestPrice,
            rolls: currentRolls,
            monthBestPrice: (betterPrice / item.time).toFixed(2),
            monthBasePrice,
          };
        })
        // 过滤价格为小于等于0的套餐
        .filter(item => item.bestPrice > 0)
        // 套餐按设备数从少到多排序
        .sort((a, b) => a - b)
        // 处理单位
        .map(item => ({
          ...item,
          whichMoney: item.currency === 'HKD'
            ? 'HK$'
            : item.currency === 'CNY' ? '￥' : '$',
          whichMoney1: item.currency === 'HKD'
            ? state.content.hk$
            : item.currency === 'CNY' ? '￥' : state.content.us$,
        }))
        // 处理名字
        .map(item => ({
          ...item,
          name: langFlag !== 1
            ? content.names[item.package - 2]
            : item.time > 1 ? `${item.time} months` : `${item.time} month`,
        }));
    },
    deviceNumberList(state, getters) {
      return [...new Set(getters.allPackages.map(item => item.device_count))];
    },
    packages(state, getters) {
      return getters.allPackages.filter(item => (
        item.device_count === (state.activeDeviceNumber || getters.deviceNumberList[0])
      ));
    },
    activeDeviceNumber(state, getters) {
      return state.activeDeviceNumber || getters.deviceNumberList[0];
    },
    activePackage(state, getters) {
      return state.activePackage || getters.packages[0];
    },
    isRecharge(state, getters) {
      return getters.deviceNumberList.length > 1;
    },
  },
});
