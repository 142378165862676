import { objSort } from '@/utils/utils';
import axios from 'axios';

function TestDomain(url) {
  return new Promise(async (resolve, reject) => {
    let res = await axios.create({
      baseURL: `https://${url}/`,
    }).request({
      url: 'test',
    })
    if (res.status == 200) resolve(url)
    else reject(url)
  })
}

export const GetFastestDomain = async function (cdnPays, cdnPaysBak, defaultPay) {
  // console.log("最快返回的域名", "开始测试", cdnPays);
  let promises = []
  for (let i = 0; i < cdnPays.length; i++) {
    let url = cdnPays[i];
    promises.push(TestDomain(url))
  }
  let cdnPay = '';
  try {
    cdnPay = await Promise.any(promises);
  } catch (e) {
    // 本次报错先不处理
  }
  if (cdnPay === '') {
    promises = []
    for (let i = 0; i < cdnPaysBak.length; i++) {
      let url = cdnPaysBak[i];
      promises.push(TestDomain(url))
    }
    try {
      cdnPay = await Promise.any(promises);
    } catch (e) {
      cdnPay = defaultPay;
    }
  }
  return cdnPay
}

// 删除无效优惠卷
const isValid = e => {
  const now = Date.now();
  const arr = [
    // 过期优惠卷，(expired为0时代表该卷不会过期)
    e.expired < now && e.expired !== 0,
    // 已使用的优惠卷
    e.state !== 0,
    // 未到激活时间
    e.active_time !== 0 && e.active_time > now,
  ];
  // 任意条件达成则返回 false
  return !arr.includes(true);
};
const deleteRolls = e => e.filter(isValid);

// 快过期的卷放前面, 套餐卷放前面, 通用卷放后面, 并删除同样的的卷
const filterExpried = e => {
  const o = objSort(objSort(e, 'expired', 0), 'package', 1);
  return o.reduce(
    (a, b) => (a.some(c => c.type === b.type && c.price === b.price) ? a : [...a, b]),
    [],
  );
};
const filterRolls = r => r.map(filterExpried);

// 筛选区分每种套餐类型可以使用的优惠券
const canUseRolls = (p, r) => {
  const arr = [];
  p.forEach((item, index) => {
    // SVIP不能使用优惠券
    if (item.isSvip) return;
    r.forEach(i => {
      arr[index] || (arr[index] = []);
      // 计算最终价格小于等于0, 则移除该优惠券
      const condition = [
        // 金额卷
        i.type === 1 && i.price >= 1 && item.price - i.price <= 0,
        // 折扣卷
        i.type === 2 && i.price < 1 && item.price * i.price <= 0,
      ];
      if (condition.includes(true)) return;
      if (i.package === item.package || i.package === 0) {
        arr[index].push(i);
      }
    });
  });
  return arr;
};

export const handleRolls = (p, r) => filterRolls(canUseRolls(p, deleteRolls(r)));

// 优惠卷排序，按优惠后总价格低到高排序
export const bestRolls = (p, r) => {
  const originalPrice = p.price;
  const arr = r.map(item => {
    let betterPrice;
    // 优惠后总价格 金额卷 || 折扣卷
    if (item.type === 1 && item.price >= 1) {
      betterPrice = originalPrice - item.price;
    } else if (item.type === 2 && item.price < 1) {
      betterPrice = originalPrice * item.price;
    } else {
      console.error(`${JSON.stringify(item)} 即不是金额卷也不是折扣券`);
    }
    // 每张优惠卷减的金额
    const diffMoney = (originalPrice - betterPrice).toFixed(2);
    betterPrice = betterPrice.toFixed(2);
    return {
      ...item,
      betterPrice,
      diffMoney,
    };
  });
  return objSort(arr, 'betterPrice', 0);
};

export const sortPackages = (packages, sort) => sort.reduce(
  (a, b) => [...a, packages.find(item => Number(item.package) === b)],
  [],
);

export const getBestPackage = packages => {
  const bestPrice = Math.min(...packages.map(item => Number(item.monthBestPrice)));
  return packages.find(item => Number(item.monthBestPrice) === bestPrice);
};
