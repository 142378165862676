<script>
import {
  watch,
  ref,
  onMounted,
} from '@vue/composition-api';
import {
  showMessage,
  operateLog,
} from '@/utils/utils';
import {
  useStore,
  useRouter,
  useContent,
  useIsMd,
} from '@/utils/hooks';
import { RechargePopup } from './recharge_popup';
import { createState } from './RchargePage';
import lang from './RechargePage.json';
import css from './RechargePage.scss';

const useLocalContent = () => useContent(lang);

const useWatch = x => {
  const store = useStore();
  const content = useLocalContent();
  const isMd = useIsMd(1024);

  watch([store.state, content, isMd], ([v1, v2, v3]) => {
    x.commit('langFlag', v1.langFlag);
    x.commit('content', v2);
    x.commit('isMd', v3);
  }, { immediate: true });
};

// 当前选中套餐索引亦是状态池依赖
const useActivePackage = x => {
  const activePackage = ref(x.state.activePackage);

  watch(activePackage, v => {
    x.commit('activePackage', v);
  });

  return activePackage;
};

// 请求获取套餐及优惠券
const useRequest = x => {
  const store = useStore();

  onMounted(() => {
    store.dispatch('init');
    const { userInfo } = store.state;
    x.dispatch('request', {
      uid: userInfo?.id,
    });
  });
};

// 购买操作, 显示充值弹窗
const useStartBuy = x => {
  const router = useRouter();
  const store = useStore();
  const content = useLocalContent();
  const showRecharge = ref(false);

  const startBuy = async () => {
    operateLog('HOME_PLAN_BUY', '', { value: x.getters.packageInfo.package });

    if (!store.state.isLogin) {
      store.dispatch('changeWantBuy', true);
      await showMessage(content.value.tip1);
      router.push('/login');
      return;
    }
    showRecharge.value = true;
  };

  return { showRecharge, startBuy };
};

export default {
  name: 'RechargePage',
  setup() {
    const content = useLocalContent();
    const isMd = useIsMd(1024);
    const x = createState();
    useWatch(x);
    useRequest(x);
    const activePackage = useActivePackage(x);
    const { showRecharge, startBuy } = useStartBuy(x);

    return {
      isMd,
      content,
      x,
      showRecharge,
      activePackage,
      startBuy,
    };
  },
  render() {
    const {
      content,
      x,
      isMd,
      startBuy,
      $imgSrcBase,
    } = this;
    const { packages } = x.getters;
    return (
      <div class={css['box-wrap']}>
        <h3>{content.title}</h3>
        <div class={css['content-box']}>
          <div class={css['set-meal-box']}>
            {isMd
              ? packages.length > 0 && packages.map(item => (
                <div
                  key={item.package}
                  class={[css['box-item'], { [css.active]: item.isActive }]}
                  onMouseenter={() => { this.activePackage = item.package; }}
                >
                  {isMd && <LazyImage src={`${$imgSrcBase}/imgs/home/${item.src}`} />}
                  <div class={css.time}>{item.name}</div>
                  <div class={css.price}>
                    <span>{item.betterPrice}</span>
                    <span>{item.isActive ? item.whichMoney1 : item.whichMoney1}</span>
                  </div>
                  {item.rolls.length > 0 && (
                    <div class={css.desc}>
                      {content.originalPrice}<span>{item.whichMoney}{item.price}, </span>
                      {content.save}{item.whichMoney}{(item.price - item.betterPrice).toFixed(2)}!
                    </div>
                  )}
                  <div class={css['buy-btn']} onClick={startBuy}>
                    {content.buyNow}
                  </div>
                  {item.rolls.length > 0 && <div class={css.off}>{item.off}</div>}
                </div>
              ))
              : packages.length > 0 && packages.map(item => (
                <div
                  key={item.id}
                  class={[css['box-item'], { [css.active]: item.isActive }]}
                  onClick={() => { isMd || (this.activePackage = item.package); }}
                >
                  {isMd && <LazyImage src={`${$imgSrcBase}/imgs/home/${item.src}`} />}
                  <div class={css.time}>{item.name}</div>
                  <div class={css.price}>
                    <span>{item.monthBestPrice}</span>
                    <span>{item.isActive ? item.whichMoney1 : item.whichMoney}</span>
                    <span>/{content.priceUnit}</span>
                  </div>
                  {item.rolls.length > 0 && (
                    <div class={css.desc}>
                      {content.originalPrice}<span>{item.whichMoney}{item.price}, </span>
                      {content.save}{item.whichMoney}{(item.price - item.betterPrice).toFixed(2)}!
                    </div>
                  )}
                  <div class={css['buy-btn']} style={`background: url('${$imgSrcBase}/imgs/home/button_sm.png') center/cover no-repeat;`} onClick={startBuy}>{content.buyNow}: {item.betterPrice} {item.whichMoney1}</div>
                  {item.rolls.length > 0 && <div class={css.off}>{item.off}</div>}
                </div>
              ))
            }
          </div>
          <div class={css['desc-box']}>{x.getters.desc.map(item => <p key={item}>{item}</p>)}</div>
        </div>
        {x.state.userId && this.showRecharge && (
          <RechargePopup
            vModel={this.showRecharge}
            key={x.getters.packageInfo.id}
            userId={x.state.userId}
            isQuickQS={false}
            packageInfo={x.getters.packageInfo}
            enabled={x.state.enabled}
            currency={x.state.currency}
            cdnPay={x.state.cdnPay}
          />
        )}
      </div>
    );
  },
};
</script>
